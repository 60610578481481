import { extendTheme, withDefaultColorScheme, theme as chakraTheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    colors: {
      brand: {
        50: '#eff6ff',
        100: '#dbeafe',
        200: '#bfdbfe',
        300: '#93c5fd',
        400: '#60a5fa',
        500: '#3b82f6',
        600: '#2563eb',
        700: '#1d4ed8',
        800: '#1e40af',
        900: '#1e3a8a',
        950: '#172554'
      }
    },
    styles: {
      global: {
        body: {
          bg: 'hsl(var(--background))'
        }
      }
    }
  },
  withDefaultColorScheme({
    colorScheme: 'brand'
  })
);

export default theme;
