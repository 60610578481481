import { useColorMode } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { colorMode } = useColorMode();

  // Used to tell tailwind which color mode to use based on chakra-ui's color mode
  useEffect(() => {
    if (colorMode === 'dark') {
      document.body.classList.add('dark');
    } else if (colorMode === 'light') {
      document.body.classList.remove('dark');
    }
  }, [colorMode]);

  return <>{children}</>;
};

export default AppLayout;
