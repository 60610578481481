import { LayoutProps } from '@atsnek/jaen';
import { Box, Heading } from '@chakra-ui/react';
import { useJaenFrameMenuContext } from 'gatsby-plugin-jaen';
import { useEffect } from 'react';

import { FaGraduationCap } from '@react-icons/all-files/fa/FaGraduationCap';
import { FaFile } from '@react-icons/all-files/fa/FaFile';
import { navigate } from 'gatsby';

const Layout: React.FC<LayoutProps> = ({ children, pageProps }) => {
  // const jaenFrameContext = useJaenFrameMenuContext();

  // useEffect(() => {
  //   jaenFrameContext.extendAddMenu({
  //     courseNew: {
  //       label: 'New Course',
  //       icon: FaGraduationCap,
  //       path: '/courses?new=true'
  //     }
  //   });
  //   jaenFrameContext.extendAddMenu({
  //     courseDocumentNew: {
  //       label: 'New Document',
  //       icon: FaFile,
  //       path: '/new/course/document'
  //     }
  //   });
  // }, []);

  return children;
};

export default Layout;
