import React from 'react';
import UserContextProvider from './src/lib/context/userContext';

require('./src/styles/globals.css');
const AppLayout = require('./src/components/AppLayout').default;

export const wrapPageElement = ({ element, props }) => {
  return (
    <UserContextProvider>
      <AppLayout {...props}>{element}</AppLayout>
    </UserContextProvider>
  );
};
