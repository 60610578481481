
import { proxy, arrayProxy, fnProxy, fnArrayProxy, t } from "snek-query";

export enum DocumentContentStatus {
    DRAFT = "DRAFT",
    UPLOADED = "UPLOADED"
}
export enum COMPLETED_WAITING_ACTIVE_DELAYED_FAILED_PAUSED_STUCK {
    completed = "completed",
    waiting = "waiting",
    active = "active",
    delayed = "delayed",
    failed = "failed",
    paused = "paused",
    stuck = "stuck"
}
export enum DocumentContentStatusInput {
    DRAFT = "DRAFT",
    UPLOADED = "UPLOADED"
}
export enum SortOrderInput {
    asc = "asc",
    desc = "desc"
}

export type ConnectionArgumentsInput = {
    first?: t.Number;
    after?: t.String;
    last?: t.Number;
    before?: t.String;
};
export type DocumentWhereInputInput = {
    AND?: DocumentWhereInputInput[];
    OR?: DocumentWhereInputInput[];
    NOT?: DocumentWhereInputInput[];
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: EnumDocumentContentStatusFilterInput;
    createdAt?: t.String;
    content?: DocumentContentListRelationFilterInput;
    course?: WithoutInput_4AndCourseWhereInputInput;
};
export type EnumDocumentContentStatusFilterInput = {
    equals?: t.String;
    in?: DocumentContentStatusInput[];
    notIn?: DocumentContentStatusInput[];
    not?: t.String;
};
export type DocumentContentListRelationFilterInput = {
    every?: DocumentContentWhereInputInput;
    some?: DocumentContentWhereInputInput;
    none?: DocumentContentWhereInputInput;
};
export type DocumentContentWhereInputInput = {
    AND?: DocumentContentWhereInputInput[];
    OR?: DocumentContentWhereInputInput[];
    NOT?: DocumentContentWhereInputInput[];
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageListRelationFilterInput;
    glossaryTerms?: GlossaryTermListRelationFilterInput;
    document?: WithoutInput_6AndDocumentWhereInputInput;
    exam?: WithoutInput_8AndExamWhereInputInput;
    chat?: WithoutInput_10AndDocumentChatWhereInputInput;
};
export type DocumentContentImageListRelationFilterInput = {
    every?: DocumentContentImageWhereInputInput;
    some?: DocumentContentImageWhereInputInput;
    none?: DocumentContentImageWhereInputInput;
};
export type DocumentContentImageWhereInputInput = {
    AND?: DocumentContentImageWhereInputInput[];
    OR?: DocumentContentImageWhereInputInput[];
    NOT?: DocumentContentImageWhereInputInput[];
    id?: t.String;
    url?: t.String;
    thumbUrl?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type WithoutInput_12AndDocumentContentWhereInputInput = {
    is?: DocumentContentWhereInputInput;
    isNot?: DocumentContentWhereInputInput;
    AND?: DocumentContentWhereInputInput[];
    OR?: DocumentContentWhereInputInput[];
    NOT?: DocumentContentWhereInputInput[];
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageListRelationFilterInput;
    glossaryTerms?: GlossaryTermListRelationFilterInput;
    document?: WithoutInput_6AndDocumentWhereInputInput;
    exam?: WithoutInput_8AndExamWhereInputInput;
    chat?: WithoutInput_10AndDocumentChatWhereInputInput;
};
export type GlossaryTermListRelationFilterInput = {
    every?: GlossaryTermWhereInputInput;
    some?: GlossaryTermWhereInputInput;
    none?: GlossaryTermWhereInputInput;
};
export type GlossaryTermWhereInputInput = {
    AND?: GlossaryTermWhereInputInput[];
    OR?: GlossaryTermWhereInputInput[];
    NOT?: GlossaryTermWhereInputInput[];
    id?: t.String;
    term?: t.String;
    definition?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type WithoutInput_6AndDocumentWhereInputInput = {
    is?: DocumentWhereInputInput;
    isNot?: DocumentWhereInputInput;
    AND?: DocumentWhereInputInput[];
    OR?: DocumentWhereInputInput[];
    NOT?: DocumentWhereInputInput[];
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: EnumDocumentContentStatusFilterInput;
    createdAt?: t.String;
    content?: DocumentContentListRelationFilterInput;
    course?: WithoutInput_4AndCourseWhereInputInput;
};
export type WithoutInput_4AndCourseWhereInputInput = {
    is?: CourseWhereInputInput;
    isNot?: CourseWhereInputInput;
    AND?: CourseWhereInputInput[];
    OR?: CourseWhereInputInput[];
    NOT?: CourseWhereInputInput[];
    id?: t.String;
    userId?: t.String;
    title?: t.String;
    documents?: DocumentListRelationFilterInput;
};
export type CourseWhereInputInput = {
    AND?: CourseWhereInputInput[];
    OR?: CourseWhereInputInput[];
    NOT?: CourseWhereInputInput[];
    id?: t.String;
    userId?: t.String;
    title?: t.String;
    documents?: DocumentListRelationFilterInput;
};
export type DocumentListRelationFilterInput = {
    every?: DocumentWhereInputInput;
    some?: DocumentWhereInputInput;
    none?: DocumentWhereInputInput;
};
export type WithoutInput_8AndExamWhereInputInput = {
    is?: ExamWhereInputInput;
    isNot?: ExamWhereInputInput;
    AND?: ExamWhereInputInput[];
    OR?: ExamWhereInputInput[];
    NOT?: ExamWhereInputInput[];
    id?: t.String;
    contentId?: t.String;
    questions?: ExamQuestionListRelationFilterInput;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type ExamWhereInputInput = {
    AND?: ExamWhereInputInput[];
    OR?: ExamWhereInputInput[];
    NOT?: ExamWhereInputInput[];
    id?: t.String;
    contentId?: t.String;
    questions?: ExamQuestionListRelationFilterInput;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type ExamQuestionListRelationFilterInput = {
    every?: ExamQuestionWhereInputInput;
    some?: ExamQuestionWhereInputInput;
    none?: ExamQuestionWhereInputInput;
};
export type ExamQuestionWhereInputInput = {
    AND?: ExamQuestionWhereInputInput[];
    OR?: ExamQuestionWhereInputInput[];
    NOT?: ExamQuestionWhereInputInput[];
    id?: t.String;
    question?: t.String;
    examId?: t.String;
    answers?: ExamQuestionAnswerListRelationFilterInput;
    exam?: WithoutInput_14AndExamWhereInputInput;
};
export type ExamQuestionAnswerListRelationFilterInput = {
    every?: ExamQuestionAnswerWhereInputInput;
    some?: ExamQuestionAnswerWhereInputInput;
    none?: ExamQuestionAnswerWhereInputInput;
};
export type ExamQuestionAnswerWhereInputInput = {
    AND?: ExamQuestionAnswerWhereInputInput[];
    OR?: ExamQuestionAnswerWhereInputInput[];
    NOT?: ExamQuestionAnswerWhereInputInput[];
    id?: t.String;
    answer?: t.String;
    isCorrect?: t.Boolean;
    examQuestionId?: t.String;
    examQuestion?: WithoutInput_16AndExamQuestionWhereInputInput;
};
export type WithoutInput_16AndExamQuestionWhereInputInput = {
    is?: ExamQuestionWhereInputInput;
    isNot?: ExamQuestionWhereInputInput;
    AND?: ExamQuestionWhereInputInput[];
    OR?: ExamQuestionWhereInputInput[];
    NOT?: ExamQuestionWhereInputInput[];
    id?: t.String;
    question?: t.String;
    examId?: t.String;
    answers?: ExamQuestionAnswerListRelationFilterInput;
    exam?: WithoutInput_14AndExamWhereInputInput;
};
export type WithoutInput_14AndExamWhereInputInput = {
    is?: ExamWhereInputInput;
    isNot?: ExamWhereInputInput;
    AND?: ExamWhereInputInput[];
    OR?: ExamWhereInputInput[];
    NOT?: ExamWhereInputInput[];
    id?: t.String;
    contentId?: t.String;
    questions?: ExamQuestionListRelationFilterInput;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type WithoutInput_10AndDocumentChatWhereInputInput = {
    is?: DocumentChatWhereInputInput;
    isNot?: DocumentChatWhereInputInput;
    AND?: DocumentChatWhereInputInput[];
    OR?: DocumentChatWhereInputInput[];
    NOT?: DocumentChatWhereInputInput[];
    id?: t.String;
    documentContentId?: t.String;
    messages?: DocumentChatMessageListRelationFilterInput;
    documentContent?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentChatWhereInputInput = {
    AND?: DocumentChatWhereInputInput[];
    OR?: DocumentChatWhereInputInput[];
    NOT?: DocumentChatWhereInputInput[];
    id?: t.String;
    documentContentId?: t.String;
    messages?: DocumentChatMessageListRelationFilterInput;
    documentContent?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentChatMessageListRelationFilterInput = {
    every?: DocumentChatMessageWhereInputInput;
    some?: DocumentChatMessageWhereInputInput;
    none?: DocumentChatMessageWhereInputInput;
};
export type DocumentChatMessageWhereInputInput = {
    AND?: DocumentChatMessageWhereInputInput[];
    OR?: DocumentChatMessageWhereInputInput[];
    NOT?: DocumentChatMessageWhereInputInput[];
    id?: t.String;
    text?: t.String;
    response?: t.String;
    chatId?: t.String;
    createdAt?: t.String;
    chat?: WithoutInput_18AndDocumentChatWhereInputInput;
};
export type WithoutInput_18AndDocumentChatWhereInputInput = {
    is?: DocumentChatWhereInputInput;
    isNot?: DocumentChatWhereInputInput;
    AND?: DocumentChatWhereInputInput[];
    OR?: DocumentChatWhereInputInput[];
    NOT?: DocumentChatWhereInputInput[];
    id?: t.String;
    documentContentId?: t.String;
    messages?: DocumentChatMessageListRelationFilterInput;
    documentContent?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    courseId?: SortOrderInput;
    title?: SortOrderInput;
    contentId?: t.String;
    readTime?: SortOrderInput;
    progress?: SortOrderInput;
    markedFinished?: SortOrderInput;
    status?: SortOrderInput;
    createdAt?: SortOrderInput;
    content?: DocumentContentOrderByRelationAggregateInputInput;
    course?: CourseOrderByWithRelationInputInput;
};
export type DocumentContentOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type CourseOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    userId?: SortOrderInput;
    title?: SortOrderInput;
    documents?: DocumentOrderByRelationAggregateInputInput;
};
export type DocumentOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type DocumentContentOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    title?: SortOrderInput;
    text?: SortOrderInput;
    progress?: SortOrderInput;
    ttsUrl?: t.String;
    documentId?: SortOrderInput;
    createdAt?: SortOrderInput;
    images?: DocumentContentImageOrderByRelationAggregateInputInput;
    glossaryTerms?: GlossaryTermOrderByRelationAggregateInputInput;
    document?: DocumentOrderByWithRelationInputInput;
    exam?: ExamOrderByWithRelationInputInput;
    chat?: DocumentChatOrderByWithRelationInputInput;
};
export type DocumentContentImageOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type GlossaryTermOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type ExamOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    contentId?: SortOrderInput;
    questions?: ExamQuestionOrderByRelationAggregateInputInput;
    content?: DocumentContentOrderByWithRelationInputInput;
};
export type ExamQuestionOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type DocumentChatOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    documentContentId?: SortOrderInput;
    messages?: DocumentChatMessageOrderByRelationAggregateInputInput;
    documentContent?: DocumentContentOrderByWithRelationInputInput;
};
export type DocumentChatMessageOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type DocumentContentImageOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    url?: SortOrderInput;
    thumbUrl?: t.String;
    contentId?: SortOrderInput;
    content?: DocumentContentOrderByWithRelationInputInput;
};
export type GlossaryTermOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    term?: SortOrderInput;
    definition?: SortOrderInput;
    contentId?: SortOrderInput;
    content?: DocumentContentOrderByWithRelationInputInput;
};
export type ExamQuestionOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    question?: SortOrderInput;
    examId?: SortOrderInput;
    answers?: ExamQuestionAnswerOrderByRelationAggregateInputInput;
    exam?: ExamOrderByWithRelationInputInput;
};
export type ExamQuestionAnswerOrderByRelationAggregateInputInput = {
    _count?: SortOrderInput;
};
export type ExamQuestionAnswerOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    answer?: SortOrderInput;
    isCorrect?: SortOrderInput;
    examQuestionId?: SortOrderInput;
    examQuestion?: ExamQuestionOrderByWithRelationInputInput;
};
export type DocumentChatMessageOrderByWithRelationInputInput = {
    id?: SortOrderInput;
    text?: SortOrderInput;
    response?: SortOrderInput;
    chatId?: SortOrderInput;
    createdAt?: SortOrderInput;
    chat?: DocumentChatOrderByWithRelationInputInput;
};
export type ObjectAndCourseUncheckedCreateInputInput = {
    id?: t.String;
    userId: t.String;
    title: t.String;
    documents?: DocumentUncheckedCreateNestedManyWithoutCourseInputInput;
};
export type DocumentUncheckedCreateNestedManyWithoutCourseInputInput = {
    create?: ObjectAndDocumentUncheckedCreateWithoutCourseInputInput;
    connectOrCreate?: DocumentCreateOrConnectWithoutCourseInputInput[];
    createMany?: DocumentCreateManyCourseInputEnvelopeInput;
    connect?: WhereInput_5AndWhereInput_4;
};
export type ObjectAndDocumentUncheckedCreateWithoutCourseInputInput = {
    id?: t.String;
    title: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: DocumentContentStatusInput;
    createdAt?: t.String;
    content?: DocumentContentUncheckedCreateNestedManyWithoutDocumentInputInput;
};
export type DocumentContentUncheckedCreateNestedManyWithoutDocumentInputInput = {
    create?: ObjectAndDocumentContentUncheckedCreateWithoutDocumentInputInput;
    connectOrCreate?: DocumentContentCreateOrConnectWithoutDocumentInputInput[];
    createMany?: DocumentContentCreateManyDocumentInputEnvelopeInput;
    connect?: WhereInput_8AndWhereInput_7;
};
export type ObjectAndDocumentContentUncheckedCreateWithoutDocumentInputInput = {
    id?: t.String;
    title: t.String;
    text: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageUncheckedCreateNestedManyWithoutContentInputInput;
    glossaryTerms?: GlossaryTermUncheckedCreateNestedManyWithoutContentInputInput;
    exam?: ExamUncheckedCreateNestedOneWithoutContentInputInput;
    chat?: DocumentChatUncheckedCreateNestedOneWithoutDocumentContentInputInput;
};
export type DocumentContentImageUncheckedCreateNestedManyWithoutContentInputInput = {
    create?: ObjectAndDocumentContentImageUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: DocumentContentImageCreateOrConnectWithoutContentInputInput[];
    createMany?: DocumentContentImageCreateManyContentInputEnvelopeInput;
    connect?: ConnectInput_2AndConnectInput_1;
};
export type ObjectAndDocumentContentImageUncheckedCreateWithoutContentInputInput = {
    id?: t.String;
    url: t.String;
    thumbUrl?: t.String;
};
export type DocumentContentImageCreateOrConnectWithoutContentInputInput = {
    where: ConnectInputAndConnectInput_1;
    create: ObjectAndDocumentContentImageUncheckedCreateWithoutContentInputInput;
};
export type ConnectInputAndConnectInput_1 = {
    id?: t.String;
    AND?: DocumentContentImageWhereInputInput[];
    OR?: DocumentContentImageWhereInputInput[];
    NOT?: DocumentContentImageWhereInputInput[];
    url?: t.String;
    thumbUrl?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentContentImageCreateManyContentInputEnvelopeInput = {
    data?: DocumentContentImageCreateManyContentInputInput[];
    skipDuplicates?: t.Boolean;
};
export type DocumentContentImageCreateManyContentInputInput = {
    id?: t.String;
    url: t.String;
    thumbUrl?: t.String;
};
export type ConnectInput_2AndConnectInput_1 = {
    id?: t.String;
    AND?: DocumentContentImageWhereInputInput[];
    OR?: DocumentContentImageWhereInputInput[];
    NOT?: DocumentContentImageWhereInputInput[];
    url?: t.String;
    thumbUrl?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type GlossaryTermUncheckedCreateNestedManyWithoutContentInputInput = {
    create?: ObjectAndGlossaryTermUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: GlossaryTermCreateOrConnectWithoutContentInputInput[];
    createMany?: GlossaryTermCreateManyContentInputEnvelopeInput;
    connect?: ConnectInput_5AndConnectInput_4;
};
export type ObjectAndGlossaryTermUncheckedCreateWithoutContentInputInput = {
    id?: t.String;
    term: t.String;
    definition: t.String;
};
export type GlossaryTermCreateOrConnectWithoutContentInputInput = {
    where: ConnectInput_3AndConnectInput_4;
    create: ObjectAndGlossaryTermUncheckedCreateWithoutContentInputInput;
};
export type ConnectInput_3AndConnectInput_4 = {
    id?: t.String;
    AND?: GlossaryTermWhereInputInput[];
    OR?: GlossaryTermWhereInputInput[];
    NOT?: GlossaryTermWhereInputInput[];
    term?: t.String;
    definition?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type GlossaryTermCreateManyContentInputEnvelopeInput = {
    data?: GlossaryTermCreateManyContentInputInput[];
    skipDuplicates?: t.Boolean;
};
export type GlossaryTermCreateManyContentInputInput = {
    id?: t.String;
    term: t.String;
    definition: t.String;
};
export type ConnectInput_5AndConnectInput_4 = {
    id?: t.String;
    AND?: GlossaryTermWhereInputInput[];
    OR?: GlossaryTermWhereInputInput[];
    NOT?: GlossaryTermWhereInputInput[];
    term?: t.String;
    definition?: t.String;
    contentId?: t.String;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type ExamUncheckedCreateNestedOneWithoutContentInputInput = {
    create?: ObjectAndExamUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: ExamCreateOrConnectWithoutContentInputInput;
    connect?: ConnectInput_6AndConnectInput_7;
};
export type ObjectAndExamUncheckedCreateWithoutContentInputInput = {
    id?: t.String;
    questions?: ExamQuestionUncheckedCreateNestedManyWithoutExamInputInput;
};
export type ExamQuestionUncheckedCreateNestedManyWithoutExamInputInput = {
    create?: ObjectAndExamQuestionUncheckedCreateWithoutExamInputInput;
    connectOrCreate?: ExamQuestionCreateOrConnectWithoutExamInputInput[];
    createMany?: ExamQuestionCreateManyExamInputEnvelopeInput;
    connect?: ConnectInput_12AndConnectInput_11;
};
export type ObjectAndExamQuestionUncheckedCreateWithoutExamInputInput = {
    id?: t.String;
    question: t.String;
    answers?: ExamQuestionAnswerUncheckedCreateNestedManyWithoutExamQuestionInputInput;
};
export type ExamQuestionAnswerUncheckedCreateNestedManyWithoutExamQuestionInputInput = {
    create?: ObjectAndExamQuestionAnswerUncheckedCreateWithoutExamQuestionInputInput;
    connectOrCreate?: ExamQuestionAnswerCreateOrConnectWithoutExamQuestionInputInput[];
    createMany?: ExamQuestionAnswerCreateManyExamQuestionInputEnvelopeInput;
    connect?: ConnectInput_15AndConnectInput_14;
};
export type ObjectAndExamQuestionAnswerUncheckedCreateWithoutExamQuestionInputInput = {
    id?: t.String;
    answer: t.String;
    isCorrect: t.Boolean;
};
export type ExamQuestionAnswerCreateOrConnectWithoutExamQuestionInputInput = {
    where: ConnectInput_13AndConnectInput_14;
    create: ObjectAndExamQuestionAnswerUncheckedCreateWithoutExamQuestionInputInput;
};
export type ConnectInput_13AndConnectInput_14 = {
    id?: t.String;
    AND?: ExamQuestionAnswerWhereInputInput[];
    OR?: ExamQuestionAnswerWhereInputInput[];
    NOT?: ExamQuestionAnswerWhereInputInput[];
    answer?: t.String;
    isCorrect?: t.Boolean;
    examQuestionId?: t.String;
    examQuestion?: WithoutInput_16AndExamQuestionWhereInputInput;
};
export type ExamQuestionAnswerCreateManyExamQuestionInputEnvelopeInput = {
    data?: ExamQuestionAnswerCreateManyExamQuestionInputInput[];
    skipDuplicates?: t.Boolean;
};
export type ExamQuestionAnswerCreateManyExamQuestionInputInput = {
    id?: t.String;
    answer: t.String;
    isCorrect: t.Boolean;
};
export type ConnectInput_15AndConnectInput_14 = {
    id?: t.String;
    AND?: ExamQuestionAnswerWhereInputInput[];
    OR?: ExamQuestionAnswerWhereInputInput[];
    NOT?: ExamQuestionAnswerWhereInputInput[];
    answer?: t.String;
    isCorrect?: t.Boolean;
    examQuestionId?: t.String;
    examQuestion?: WithoutInput_16AndExamQuestionWhereInputInput;
};
export type ExamQuestionCreateOrConnectWithoutExamInputInput = {
    where: ConnectInput_10AndConnectInput_11;
    create: ObjectAndExamQuestionUncheckedCreateWithoutExamInputInput;
};
export type ConnectInput_10AndConnectInput_11 = {
    id?: t.String;
    AND?: ExamQuestionWhereInputInput[];
    OR?: ExamQuestionWhereInputInput[];
    NOT?: ExamQuestionWhereInputInput[];
    question?: t.String;
    examId?: t.String;
    answers?: ExamQuestionAnswerListRelationFilterInput;
    exam?: WithoutInput_14AndExamWhereInputInput;
};
export type ExamQuestionCreateManyExamInputEnvelopeInput = {
    data?: ExamQuestionCreateManyExamInputInput[];
    skipDuplicates?: t.Boolean;
};
export type ExamQuestionCreateManyExamInputInput = {
    id?: t.String;
    question: t.String;
};
export type ConnectInput_12AndConnectInput_11 = {
    id?: t.String;
    AND?: ExamQuestionWhereInputInput[];
    OR?: ExamQuestionWhereInputInput[];
    NOT?: ExamQuestionWhereInputInput[];
    question?: t.String;
    examId?: t.String;
    answers?: ExamQuestionAnswerListRelationFilterInput;
    exam?: WithoutInput_14AndExamWhereInputInput;
};
export type ExamCreateOrConnectWithoutContentInputInput = {
    where: ConnectInput_6AndConnectInput_7;
    create: ObjectAndExamUncheckedCreateWithoutContentInputInput;
};
export type ConnectInput_6AndConnectInput_7 = {
    id?: t.String;
    contentId?: t.String;
    AND?: ExamWhereInputInput[];
    OR?: ExamWhereInputInput[];
    NOT?: ExamWhereInputInput[];
    questions?: ExamQuestionListRelationFilterInput;
    content?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentChatUncheckedCreateNestedOneWithoutDocumentContentInputInput = {
    create?: ObjectAndDocumentChatUncheckedCreateWithoutDocumentContentInputInput;
    connectOrCreate?: DocumentChatCreateOrConnectWithoutDocumentContentInputInput;
    connect?: ConnectInput_16AndConnectInput_17;
};
export type ObjectAndDocumentChatUncheckedCreateWithoutDocumentContentInputInput = {
    id?: t.String;
    messages?: DocumentChatMessageUncheckedCreateNestedManyWithoutChatInputInput;
};
export type DocumentChatMessageUncheckedCreateNestedManyWithoutChatInputInput = {
    create?: ObjectAndDocumentChatMessageUncheckedCreateWithoutChatInputInput;
    connectOrCreate?: DocumentChatMessageCreateOrConnectWithoutChatInputInput[];
    createMany?: DocumentChatMessageCreateManyChatInputEnvelopeInput;
    connect?: ConnectInput_22AndConnectInput_21;
};
export type ObjectAndDocumentChatMessageUncheckedCreateWithoutChatInputInput = {
    id?: t.String;
    text: t.String;
    response: t.String;
    createdAt?: t.String;
};
export type DocumentChatMessageCreateOrConnectWithoutChatInputInput = {
    where: ConnectInput_20AndConnectInput_21;
    create: ObjectAndDocumentChatMessageUncheckedCreateWithoutChatInputInput;
};
export type ConnectInput_20AndConnectInput_21 = {
    id?: t.String;
    AND?: DocumentChatMessageWhereInputInput[];
    OR?: DocumentChatMessageWhereInputInput[];
    NOT?: DocumentChatMessageWhereInputInput[];
    text?: t.String;
    response?: t.String;
    chatId?: t.String;
    createdAt?: t.String;
    chat?: WithoutInput_18AndDocumentChatWhereInputInput;
};
export type DocumentChatMessageCreateManyChatInputEnvelopeInput = {
    data?: DocumentChatMessageCreateManyChatInputInput[];
    skipDuplicates?: t.Boolean;
};
export type DocumentChatMessageCreateManyChatInputInput = {
    id?: t.String;
    text: t.String;
    response: t.String;
    createdAt?: t.String;
};
export type ConnectInput_22AndConnectInput_21 = {
    id?: t.String;
    AND?: DocumentChatMessageWhereInputInput[];
    OR?: DocumentChatMessageWhereInputInput[];
    NOT?: DocumentChatMessageWhereInputInput[];
    text?: t.String;
    response?: t.String;
    chatId?: t.String;
    createdAt?: t.String;
    chat?: WithoutInput_18AndDocumentChatWhereInputInput;
};
export type DocumentChatCreateOrConnectWithoutDocumentContentInputInput = {
    where: ConnectInput_16AndConnectInput_17;
    create: ObjectAndDocumentChatUncheckedCreateWithoutDocumentContentInputInput;
};
export type ConnectInput_16AndConnectInput_17 = {
    id?: t.String;
    documentContentId?: t.String;
    AND?: DocumentChatWhereInputInput[];
    OR?: DocumentChatWhereInputInput[];
    NOT?: DocumentChatWhereInputInput[];
    messages?: DocumentChatMessageListRelationFilterInput;
    documentContent?: WithoutInput_12AndDocumentContentWhereInputInput;
};
export type DocumentContentCreateOrConnectWithoutDocumentInputInput = {
    where: WhereInput_6AndWhereInput_7;
    create: ObjectAndDocumentContentUncheckedCreateWithoutDocumentInputInput;
};
export type WhereInput_6AndWhereInput_7 = {
    id?: t.String;
    AND?: DocumentContentWhereInputInput[];
    OR?: DocumentContentWhereInputInput[];
    NOT?: DocumentContentWhereInputInput[];
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageListRelationFilterInput;
    glossaryTerms?: GlossaryTermListRelationFilterInput;
    document?: WithoutInput_6AndDocumentWhereInputInput;
    exam?: WithoutInput_8AndExamWhereInputInput;
    chat?: WithoutInput_10AndDocumentChatWhereInputInput;
};
export type DocumentContentCreateManyDocumentInputEnvelopeInput = {
    data?: DocumentContentCreateManyDocumentInputInput[];
    skipDuplicates?: t.Boolean;
};
export type DocumentContentCreateManyDocumentInputInput = {
    id?: t.String;
    title: t.String;
    text: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    createdAt?: t.String;
};
export type WhereInput_8AndWhereInput_7 = {
    id?: t.String;
    AND?: DocumentContentWhereInputInput[];
    OR?: DocumentContentWhereInputInput[];
    NOT?: DocumentContentWhereInputInput[];
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageListRelationFilterInput;
    glossaryTerms?: GlossaryTermListRelationFilterInput;
    document?: WithoutInput_6AndDocumentWhereInputInput;
    exam?: WithoutInput_8AndExamWhereInputInput;
    chat?: WithoutInput_10AndDocumentChatWhereInputInput;
};
export type DocumentCreateOrConnectWithoutCourseInputInput = {
    where: WhereInput_3AndWhereInput_4;
    create: ObjectAndDocumentUncheckedCreateWithoutCourseInputInput;
};
export type WhereInput_3AndWhereInput_4 = {
    id?: t.String;
    AND?: DocumentWhereInputInput[];
    OR?: DocumentWhereInputInput[];
    NOT?: DocumentWhereInputInput[];
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: EnumDocumentContentStatusFilterInput;
    createdAt?: t.String;
    content?: DocumentContentListRelationFilterInput;
    course?: WithoutInput_4AndCourseWhereInputInput;
};
export type DocumentCreateManyCourseInputEnvelopeInput = {
    data?: DocumentCreateManyCourseInputInput[];
    skipDuplicates?: t.Boolean;
};
export type DocumentCreateManyCourseInputInput = {
    id?: t.String;
    title: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: DocumentContentStatusInput;
    createdAt?: t.String;
};
export type WhereInput_5AndWhereInput_4 = {
    id?: t.String;
    AND?: DocumentWhereInputInput[];
    OR?: DocumentWhereInputInput[];
    NOT?: DocumentWhereInputInput[];
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: EnumDocumentContentStatusFilterInput;
    createdAt?: t.String;
    content?: DocumentContentListRelationFilterInput;
    course?: WithoutInput_4AndCourseWhereInputInput;
};
export type ObjectAndCourseUncheckedUpdateInputInput = {
    id?: t.String;
    userId?: t.String;
    title?: t.String;
    documents?: DocumentUncheckedUpdateManyWithoutCourseNestedInputInput;
};
export type DocumentUncheckedUpdateManyWithoutCourseNestedInputInput = {
    create?: ObjectAndDocumentUncheckedCreateWithoutCourseInputInput;
    connectOrCreate?: DocumentCreateOrConnectWithoutCourseInputInput[];
    upsert?: DocumentUpsertWithWhereUniqueWithoutCourseInputInput[];
    createMany?: DocumentCreateManyCourseInputEnvelopeInput;
    set?: WhereInput_5AndWhereInput_4;
    disconnect?: WhereInput_5AndWhereInput_4;
    delete?: WhereInput_5AndWhereInput_4;
    connect?: WhereInput_5AndWhereInput_4;
    update?: DocumentUpdateWithWhereUniqueWithoutCourseInputInput[];
    updateMany?: DocumentUpdateManyWithWhereWithoutCourseInputInput[];
    deleteMany?: DocumentScalarWhereInputInput[];
};
export type DocumentUpsertWithWhereUniqueWithoutCourseInputInput = {
    where: WhereInput_3AndWhereInput_4;
    update: ObjectAndDocumentUncheckedUpdateWithoutCourseInputInput;
    create: ObjectAndDocumentUncheckedCreateWithoutCourseInputInput;
};
export type ObjectAndDocumentUncheckedUpdateWithoutCourseInputInput = {
    id?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: t.String;
    createdAt?: t.String;
    content?: DocumentContentUncheckedUpdateManyWithoutDocumentNestedInputInput;
};
export type DocumentContentUncheckedUpdateManyWithoutDocumentNestedInputInput = {
    create?: ObjectAndDocumentContentUncheckedCreateWithoutDocumentInputInput;
    connectOrCreate?: DocumentContentCreateOrConnectWithoutDocumentInputInput[];
    upsert?: DocumentContentUpsertWithWhereUniqueWithoutDocumentInputInput[];
    createMany?: DocumentContentCreateManyDocumentInputEnvelopeInput;
    set?: WhereInput_8AndWhereInput_7;
    disconnect?: WhereInput_8AndWhereInput_7;
    delete?: WhereInput_8AndWhereInput_7;
    connect?: WhereInput_8AndWhereInput_7;
    update?: DocumentContentUpdateWithWhereUniqueWithoutDocumentInputInput[];
    updateMany?: DocumentContentUpdateManyWithWhereWithoutDocumentInputInput[];
    deleteMany?: DocumentContentScalarWhereInputInput[];
};
export type DocumentContentUpsertWithWhereUniqueWithoutDocumentInputInput = {
    where: WhereInput_6AndWhereInput_7;
    update: ObjectAndDocumentContentUncheckedUpdateWithoutDocumentInputInput;
    create: ObjectAndDocumentContentUncheckedCreateWithoutDocumentInputInput;
};
export type ObjectAndDocumentContentUncheckedUpdateWithoutDocumentInputInput = {
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageUncheckedUpdateManyWithoutContentNestedInputInput;
    glossaryTerms?: GlossaryTermUncheckedUpdateManyWithoutContentNestedInputInput;
    exam?: ExamUncheckedUpdateOneWithoutContentNestedInputInput;
    chat?: DocumentChatUncheckedUpdateOneWithoutDocumentContentNestedInputInput;
};
export type DocumentContentImageUncheckedUpdateManyWithoutContentNestedInputInput = {
    create?: ObjectAndDocumentContentImageUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: DocumentContentImageCreateOrConnectWithoutContentInputInput[];
    upsert?: DocumentContentImageUpsertWithWhereUniqueWithoutContentInputInput[];
    createMany?: DocumentContentImageCreateManyContentInputEnvelopeInput;
    set?: ConnectInput_2AndConnectInput_1;
    disconnect?: ConnectInput_2AndConnectInput_1;
    delete?: ConnectInput_2AndConnectInput_1;
    connect?: ConnectInput_2AndConnectInput_1;
    update?: DocumentContentImageUpdateWithWhereUniqueWithoutContentInputInput[];
    updateMany?: DocumentContentImageUpdateManyWithWhereWithoutContentInputInput[];
    deleteMany?: DocumentContentImageScalarWhereInputInput[];
};
export type DocumentContentImageUpsertWithWhereUniqueWithoutContentInputInput = {
    where: ConnectInputAndConnectInput_1;
    update: ObjectAndDocumentContentImageUncheckedUpdateWithoutContentInputInput;
    create: ObjectAndDocumentContentImageUncheckedCreateWithoutContentInputInput;
};
export type ObjectAndDocumentContentImageUncheckedUpdateWithoutContentInputInput = {
    id?: t.String;
    url?: t.String;
    thumbUrl?: t.String;
};
export type DocumentContentImageUpdateWithWhereUniqueWithoutContentInputInput = {
    where: ConnectInputAndConnectInput_1;
    data: ObjectAndDocumentContentImageUncheckedUpdateWithoutContentInputInput;
};
export type DocumentContentImageUpdateManyWithWhereWithoutContentInputInput = {
    where: DocumentContentImageScalarWhereInputInput;
    data: ObjectAndDocumentContentImageUncheckedUpdateManyWithoutContentInputInput;
};
export type DocumentContentImageScalarWhereInputInput = {
    AND?: DocumentContentImageScalarWhereInputInput[];
    OR?: DocumentContentImageScalarWhereInputInput[];
    NOT?: DocumentContentImageScalarWhereInputInput[];
    id?: t.String;
    url?: t.String;
    thumbUrl?: t.String;
    contentId?: t.String;
};
export type ObjectAndDocumentContentImageUncheckedUpdateManyWithoutContentInputInput = {
    id?: t.String;
    url?: t.String;
    thumbUrl?: t.String;
};
export type GlossaryTermUncheckedUpdateManyWithoutContentNestedInputInput = {
    create?: ObjectAndGlossaryTermUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: GlossaryTermCreateOrConnectWithoutContentInputInput[];
    upsert?: GlossaryTermUpsertWithWhereUniqueWithoutContentInputInput[];
    createMany?: GlossaryTermCreateManyContentInputEnvelopeInput;
    set?: ConnectInput_5AndConnectInput_4;
    disconnect?: ConnectInput_5AndConnectInput_4;
    delete?: ConnectInput_5AndConnectInput_4;
    connect?: ConnectInput_5AndConnectInput_4;
    update?: GlossaryTermUpdateWithWhereUniqueWithoutContentInputInput[];
    updateMany?: GlossaryTermUpdateManyWithWhereWithoutContentInputInput[];
    deleteMany?: GlossaryTermScalarWhereInputInput[];
};
export type GlossaryTermUpsertWithWhereUniqueWithoutContentInputInput = {
    where: ConnectInput_3AndConnectInput_4;
    update: ObjectAndGlossaryTermUncheckedUpdateWithoutContentInputInput;
    create: ObjectAndGlossaryTermUncheckedCreateWithoutContentInputInput;
};
export type ObjectAndGlossaryTermUncheckedUpdateWithoutContentInputInput = {
    id?: t.String;
    term?: t.String;
    definition?: t.String;
};
export type GlossaryTermUpdateWithWhereUniqueWithoutContentInputInput = {
    where: ConnectInput_3AndConnectInput_4;
    data: ObjectAndGlossaryTermUncheckedUpdateWithoutContentInputInput;
};
export type GlossaryTermUpdateManyWithWhereWithoutContentInputInput = {
    where: GlossaryTermScalarWhereInputInput;
    data: ObjectAndGlossaryTermUncheckedUpdateManyWithoutContentInputInput;
};
export type GlossaryTermScalarWhereInputInput = {
    AND?: GlossaryTermScalarWhereInputInput[];
    OR?: GlossaryTermScalarWhereInputInput[];
    NOT?: GlossaryTermScalarWhereInputInput[];
    id?: t.String;
    term?: t.String;
    definition?: t.String;
    contentId?: t.String;
};
export type ObjectAndGlossaryTermUncheckedUpdateManyWithoutContentInputInput = {
    id?: t.String;
    term?: t.String;
    definition?: t.String;
};
export type ExamUncheckedUpdateOneWithoutContentNestedInputInput = {
    create?: ObjectAndExamUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: ExamCreateOrConnectWithoutContentInputInput;
    upsert?: ExamUpsertWithoutContentInputInput;
    disconnect?: t.Boolean;
    delete?: t.Boolean;
    connect?: ConnectInput_6AndConnectInput_7;
    update?: WithoutInput_20AndExamUncheckedUpdateWithoutContentInputInput;
};
export type ExamUpsertWithoutContentInputInput = {
    update: ObjectAndExamUncheckedUpdateWithoutContentInputInput;
    create: ObjectAndExamUncheckedCreateWithoutContentInputInput;
    where?: ExamWhereInputInput;
};
export type ObjectAndExamUncheckedUpdateWithoutContentInputInput = {
    id?: t.String;
    questions?: ExamQuestionUncheckedUpdateManyWithoutExamNestedInputInput;
};
export type ExamQuestionUncheckedUpdateManyWithoutExamNestedInputInput = {
    create?: ObjectAndExamQuestionUncheckedCreateWithoutExamInputInput;
    connectOrCreate?: ExamQuestionCreateOrConnectWithoutExamInputInput[];
    upsert?: ExamQuestionUpsertWithWhereUniqueWithoutExamInputInput[];
    createMany?: ExamQuestionCreateManyExamInputEnvelopeInput;
    set?: ConnectInput_12AndConnectInput_11;
    disconnect?: ConnectInput_12AndConnectInput_11;
    delete?: ConnectInput_12AndConnectInput_11;
    connect?: ConnectInput_12AndConnectInput_11;
    update?: ExamQuestionUpdateWithWhereUniqueWithoutExamInputInput[];
    updateMany?: ExamQuestionUpdateManyWithWhereWithoutExamInputInput[];
    deleteMany?: ExamQuestionScalarWhereInputInput[];
};
export type ExamQuestionUpsertWithWhereUniqueWithoutExamInputInput = {
    where: ConnectInput_10AndConnectInput_11;
    update: ObjectAndExamQuestionUncheckedUpdateWithoutExamInputInput;
    create: ObjectAndExamQuestionUncheckedCreateWithoutExamInputInput;
};
export type ObjectAndExamQuestionUncheckedUpdateWithoutExamInputInput = {
    id?: t.String;
    question?: t.String;
    answers?: ExamQuestionAnswerUncheckedUpdateManyWithoutExamQuestionNestedInputInput;
};
export type ExamQuestionAnswerUncheckedUpdateManyWithoutExamQuestionNestedInputInput = {
    create?: ObjectAndExamQuestionAnswerUncheckedCreateWithoutExamQuestionInputInput;
    connectOrCreate?: ExamQuestionAnswerCreateOrConnectWithoutExamQuestionInputInput[];
    upsert?: ExamQuestionAnswerUpsertWithWhereUniqueWithoutExamQuestionInputInput[];
    createMany?: ExamQuestionAnswerCreateManyExamQuestionInputEnvelopeInput;
    set?: ConnectInput_15AndConnectInput_14;
    disconnect?: ConnectInput_15AndConnectInput_14;
    delete?: ConnectInput_15AndConnectInput_14;
    connect?: ConnectInput_15AndConnectInput_14;
    update?: ExamQuestionAnswerUpdateWithWhereUniqueWithoutExamQuestionInputInput[];
    updateMany?: ExamQuestionAnswerUpdateManyWithWhereWithoutExamQuestionInputInput[];
    deleteMany?: ExamQuestionAnswerScalarWhereInputInput[];
};
export type ExamQuestionAnswerUpsertWithWhereUniqueWithoutExamQuestionInputInput = {
    where: ConnectInput_13AndConnectInput_14;
    update: ObjectAndExamQuestionAnswerUncheckedUpdateWithoutExamQuestionInputInput;
    create: ObjectAndExamQuestionAnswerUncheckedCreateWithoutExamQuestionInputInput;
};
export type ObjectAndExamQuestionAnswerUncheckedUpdateWithoutExamQuestionInputInput = {
    id?: t.String;
    answer?: t.String;
    isCorrect?: t.Boolean;
};
export type ExamQuestionAnswerUpdateWithWhereUniqueWithoutExamQuestionInputInput = {
    where: ConnectInput_13AndConnectInput_14;
    data: ObjectAndExamQuestionAnswerUncheckedUpdateWithoutExamQuestionInputInput;
};
export type ExamQuestionAnswerUpdateManyWithWhereWithoutExamQuestionInputInput = {
    where: ExamQuestionAnswerScalarWhereInputInput;
    data: ObjectAndExamQuestionAnswerUncheckedUpdateManyWithoutExamQuestionInputInput;
};
export type ExamQuestionAnswerScalarWhereInputInput = {
    AND?: ExamQuestionAnswerScalarWhereInputInput[];
    OR?: ExamQuestionAnswerScalarWhereInputInput[];
    NOT?: ExamQuestionAnswerScalarWhereInputInput[];
    id?: t.String;
    answer?: t.String;
    isCorrect?: t.Boolean;
    examQuestionId?: t.String;
};
export type ObjectAndExamQuestionAnswerUncheckedUpdateManyWithoutExamQuestionInputInput = {
    id?: t.String;
    answer?: t.String;
    isCorrect?: t.Boolean;
};
export type ExamQuestionUpdateWithWhereUniqueWithoutExamInputInput = {
    where: ConnectInput_10AndConnectInput_11;
    data: ObjectAndExamQuestionUncheckedUpdateWithoutExamInputInput;
};
export type ExamQuestionUpdateManyWithWhereWithoutExamInputInput = {
    where: ExamQuestionScalarWhereInputInput;
    data: ObjectAndExamQuestionUncheckedUpdateManyWithoutExamInputInput;
};
export type ExamQuestionScalarWhereInputInput = {
    AND?: ExamQuestionScalarWhereInputInput[];
    OR?: ExamQuestionScalarWhereInputInput[];
    NOT?: ExamQuestionScalarWhereInputInput[];
    id?: t.String;
    question?: t.String;
    examId?: t.String;
};
export type ObjectAndExamQuestionUncheckedUpdateManyWithoutExamInputInput = {
    id?: t.String;
    question?: t.String;
};
export type WithoutInput_20AndExamUncheckedUpdateWithoutContentInputInput = {
    where?: ExamWhereInputInput;
    data?: ObjectAndExamUncheckedUpdateWithoutContentInputInput;
    id?: t.String;
    questions?: ExamQuestionUncheckedUpdateManyWithoutExamNestedInputInput;
};
export type DocumentChatUncheckedUpdateOneWithoutDocumentContentNestedInputInput = {
    create?: ObjectAndDocumentChatUncheckedCreateWithoutDocumentContentInputInput;
    connectOrCreate?: DocumentChatCreateOrConnectWithoutDocumentContentInputInput;
    upsert?: DocumentChatUpsertWithoutDocumentContentInputInput;
    disconnect?: t.Boolean;
    delete?: t.Boolean;
    connect?: ConnectInput_16AndConnectInput_17;
    update?: WithoutInput_24AndDocumentChatUncheckedUpdateWithoutDocumentContentInputInput;
};
export type DocumentChatUpsertWithoutDocumentContentInputInput = {
    update: ObjectAndDocumentChatUncheckedUpdateWithoutDocumentContentInputInput;
    create: ObjectAndDocumentChatUncheckedCreateWithoutDocumentContentInputInput;
    where?: DocumentChatWhereInputInput;
};
export type ObjectAndDocumentChatUncheckedUpdateWithoutDocumentContentInputInput = {
    id?: t.String;
    messages?: DocumentChatMessageUncheckedUpdateManyWithoutChatNestedInputInput;
};
export type DocumentChatMessageUncheckedUpdateManyWithoutChatNestedInputInput = {
    create?: ObjectAndDocumentChatMessageUncheckedCreateWithoutChatInputInput;
    connectOrCreate?: DocumentChatMessageCreateOrConnectWithoutChatInputInput[];
    upsert?: DocumentChatMessageUpsertWithWhereUniqueWithoutChatInputInput[];
    createMany?: DocumentChatMessageCreateManyChatInputEnvelopeInput;
    set?: ConnectInput_22AndConnectInput_21;
    disconnect?: ConnectInput_22AndConnectInput_21;
    delete?: ConnectInput_22AndConnectInput_21;
    connect?: ConnectInput_22AndConnectInput_21;
    update?: DocumentChatMessageUpdateWithWhereUniqueWithoutChatInputInput[];
    updateMany?: DocumentChatMessageUpdateManyWithWhereWithoutChatInputInput[];
    deleteMany?: DocumentChatMessageScalarWhereInputInput[];
};
export type DocumentChatMessageUpsertWithWhereUniqueWithoutChatInputInput = {
    where: ConnectInput_20AndConnectInput_21;
    update: ObjectAndDocumentChatMessageUncheckedUpdateWithoutChatInputInput;
    create: ObjectAndDocumentChatMessageUncheckedCreateWithoutChatInputInput;
};
export type ObjectAndDocumentChatMessageUncheckedUpdateWithoutChatInputInput = {
    id?: t.String;
    text?: t.String;
    response?: t.String;
    createdAt?: t.String;
};
export type DocumentChatMessageUpdateWithWhereUniqueWithoutChatInputInput = {
    where: ConnectInput_20AndConnectInput_21;
    data: ObjectAndDocumentChatMessageUncheckedUpdateWithoutChatInputInput;
};
export type DocumentChatMessageUpdateManyWithWhereWithoutChatInputInput = {
    where: DocumentChatMessageScalarWhereInputInput;
    data: ObjectAndDocumentChatMessageUncheckedUpdateManyWithoutChatInputInput;
};
export type DocumentChatMessageScalarWhereInputInput = {
    AND?: DocumentChatMessageScalarWhereInputInput[];
    OR?: DocumentChatMessageScalarWhereInputInput[];
    NOT?: DocumentChatMessageScalarWhereInputInput[];
    id?: t.String;
    text?: t.String;
    response?: t.String;
    chatId?: t.String;
    createdAt?: t.String;
};
export type ObjectAndDocumentChatMessageUncheckedUpdateManyWithoutChatInputInput = {
    id?: t.String;
    text?: t.String;
    response?: t.String;
    createdAt?: t.String;
};
export type WithoutInput_24AndDocumentChatUncheckedUpdateWithoutDocumentContentInputInput = {
    where?: DocumentChatWhereInputInput;
    data?: ObjectAndDocumentChatUncheckedUpdateWithoutDocumentContentInputInput;
    id?: t.String;
    messages?: DocumentChatMessageUncheckedUpdateManyWithoutChatNestedInputInput;
};
export type DocumentContentUpdateWithWhereUniqueWithoutDocumentInputInput = {
    where: WhereInput_6AndWhereInput_7;
    data: ObjectAndDocumentContentUncheckedUpdateWithoutDocumentInputInput;
};
export type DocumentContentUpdateManyWithWhereWithoutDocumentInputInput = {
    where: DocumentContentScalarWhereInputInput;
    data: ObjectAndDocumentContentUncheckedUpdateManyWithoutDocumentInputInput;
};
export type DocumentContentScalarWhereInputInput = {
    AND?: DocumentContentScalarWhereInputInput[];
    OR?: DocumentContentScalarWhereInputInput[];
    NOT?: DocumentContentScalarWhereInputInput[];
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
};
export type ObjectAndDocumentContentUncheckedUpdateManyWithoutDocumentInputInput = {
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    createdAt?: t.String;
};
export type DocumentUpdateWithWhereUniqueWithoutCourseInputInput = {
    where: WhereInput_3AndWhereInput_4;
    data: ObjectAndDocumentUncheckedUpdateWithoutCourseInputInput;
};
export type DocumentUpdateManyWithWhereWithoutCourseInputInput = {
    where: DocumentScalarWhereInputInput;
    data: ObjectAndDocumentUncheckedUpdateManyWithoutCourseInputInput;
};
export type DocumentScalarWhereInputInput = {
    AND?: DocumentScalarWhereInputInput[];
    OR?: DocumentScalarWhereInputInput[];
    NOT?: DocumentScalarWhereInputInput[];
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: EnumDocumentContentStatusFilterInput;
    createdAt?: t.String;
};
export type ObjectAndDocumentUncheckedUpdateManyWithoutCourseInputInput = {
    id?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: t.String;
    createdAt?: t.String;
};
export type WhereInputAndWhereInput_1 = {
    id?: t.String;
    AND?: CourseWhereInputInput[];
    OR?: CourseWhereInputInput[];
    NOT?: CourseWhereInputInput[];
    userId?: t.String;
    title?: t.String;
    documents?: DocumentListRelationFilterInput;
};
export type ArgsInput = {
    userId: t.String;
    courseId: t.String;
    documentFileId: t.String;
};
export type WithoutInputAndDocumentUncheckedUpdateInputInput = {
    course?: CourseUpdateOneRequiredWithoutDocumentsNestedInputInput;
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: t.String;
    createdAt?: t.String;
    content?: DocumentContentUncheckedUpdateManyWithoutDocumentNestedInputInput;
};
export type CourseUpdateOneRequiredWithoutDocumentsNestedInputInput = {
    create?: ObjectAndCourseUncheckedCreateWithoutDocumentsInputInput;
    connectOrCreate?: CourseCreateOrConnectWithoutDocumentsInputInput;
    upsert?: CourseUpsertWithoutDocumentsInputInput;
    connect?: WhereInputAndWhereInput_1;
    update?: WithoutInput_28AndCourseUncheckedUpdateWithoutDocumentsInputInput;
};
export type ObjectAndCourseUncheckedCreateWithoutDocumentsInputInput = {
    id?: t.String;
    userId: t.String;
    title: t.String;
};
export type CourseCreateOrConnectWithoutDocumentsInputInput = {
    where: WhereInputAndWhereInput_1;
    create: ObjectAndCourseUncheckedCreateWithoutDocumentsInputInput;
};
export type CourseUpsertWithoutDocumentsInputInput = {
    update: ObjectAndCourseUncheckedUpdateWithoutDocumentsInputInput;
    create: ObjectAndCourseUncheckedCreateWithoutDocumentsInputInput;
    where?: CourseWhereInputInput;
};
export type ObjectAndCourseUncheckedUpdateWithoutDocumentsInputInput = {
    id?: t.String;
    userId?: t.String;
    title?: t.String;
};
export type WithoutInput_28AndCourseUncheckedUpdateWithoutDocumentsInputInput = {
    where?: CourseWhereInputInput;
    data?: ObjectAndCourseUncheckedUpdateWithoutDocumentsInputInput;
    id?: t.String;
    userId?: t.String;
    title?: t.String;
};
export type WithoutInput_2AndDocumentContentUncheckedUpdateInputInput = {
    document?: DocumentUpdateOneRequiredWithoutContentNestedInputInput;
    id?: t.String;
    title?: t.String;
    text?: t.String;
    progress?: t.Number;
    ttsUrl?: t.String;
    documentId?: t.String;
    createdAt?: t.String;
    images?: DocumentContentImageUncheckedUpdateManyWithoutContentNestedInputInput;
    glossaryTerms?: GlossaryTermUncheckedUpdateManyWithoutContentNestedInputInput;
    exam?: ExamUncheckedUpdateOneWithoutContentNestedInputInput;
    chat?: DocumentChatUncheckedUpdateOneWithoutDocumentContentNestedInputInput;
};
export type DocumentUpdateOneRequiredWithoutContentNestedInputInput = {
    create?: WithoutInput_32AndDocumentUncheckedCreateWithoutContentInputInput;
    connectOrCreate?: DocumentCreateOrConnectWithoutContentInputInput;
    upsert?: DocumentUpsertWithoutContentInputInput;
    connect?: WhereInput_3AndWhereInput_4;
    update?: WithoutInput_34AndDocumentUncheckedUpdateWithoutContentInputInput;
};
export type WithoutInput_32AndDocumentUncheckedCreateWithoutContentInputInput = {
    course?: CourseCreateNestedOneWithoutDocumentsInputInput;
    id?: t.String;
    courseId?: t.String;
    title: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: DocumentContentStatusInput;
    createdAt?: t.String;
};
export type CourseCreateNestedOneWithoutDocumentsInputInput = {
    create?: ObjectAndCourseUncheckedCreateWithoutDocumentsInputInput;
    connectOrCreate?: CourseCreateOrConnectWithoutDocumentsInputInput;
    connect?: WhereInputAndWhereInput_1;
};
export type DocumentCreateOrConnectWithoutContentInputInput = {
    where: WhereInput_3AndWhereInput_4;
    create: WithoutInput_32AndDocumentUncheckedCreateWithoutContentInputInput;
};
export type DocumentUpsertWithoutContentInputInput = {
    update: WithoutInput_40AndDocumentUncheckedUpdateWithoutContentInputInput;
    create: WithoutInput_32AndDocumentUncheckedCreateWithoutContentInputInput;
    where?: DocumentWhereInputInput;
};
export type WithoutInput_40AndDocumentUncheckedUpdateWithoutContentInputInput = {
    course?: CourseUpdateOneRequiredWithoutDocumentsNestedInputInput;
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: t.String;
    createdAt?: t.String;
};
export type WithoutInput_34AndDocumentUncheckedUpdateWithoutContentInputInput = {
    where?: DocumentWhereInputInput;
    course?: CourseUpdateOneRequiredWithoutDocumentsNestedInputInput;
    data?: WithoutInput_40AndDocumentUncheckedUpdateWithoutContentInputInput;
    id?: t.String;
    courseId?: t.String;
    title?: t.String;
    contentId?: t.String;
    readTime?: t.Number;
    progress?: t.Number;
    markedFinished?: t.Boolean;
    status?: t.String;
    createdAt?: t.String;
};

export class Query {
    __typename: t.String;
    allCourse: (args: {
        userId: t.String;
    }) => Connection;
    course: (args: {
        userId: t.String;
        id: t.String;
    }) => Course;
    courseDocument: (args: {
        userId: t.String;
        id: t.String;
    }) => Document;
    courseDocumentContent: (args: {
        id: t.String;
    }) => DocumentContent;
    courseDocumentUpload: (args: {
        id: t.String;
    }) => Task;
    userToken: (args: {
        userId: t.String;
    }) => UserToken;
    version: t.String;
    constructor() { this.__typename = ""; this.allCourse = fnProxy(Connection); this.course = fnProxy(Course); this.courseDocument = fnProxy(Document); this.courseDocumentContent = fnProxy(DocumentContent); this.courseDocumentUpload = fnProxy(Task); this.userToken = fnProxy(UserToken); this.version = ""; }
}
export class Connection {
    __typename: t.String;
    nodes: Course[];
    edges: Edge[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(Course); this.edges = arrayProxy(Edge); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class Course {
    __typename: t.String;
    id: t.String;
    userId: t.String;
    title: t.String;
    documents: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: DocumentWhereInputInput;
        orderBy?: DocumentOrderByWithRelationInputInput[];
    }) => Connection_1;
    constructor() { this.__typename = ""; this.id = ""; this.userId = ""; this.title = ""; this.documents = fnProxy(Connection_1); }
}
export class Connection_1 {
    __typename: t.String;
    nodes: Document[];
    edges: Edge_1[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(Document); this.edges = arrayProxy(Edge_1); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class Document {
    __typename: t.String;
    id: t.String;
    title: t.String;
    contentId: t.Nullable<t.String>;
    content: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: DocumentContentWhereInputInput;
        orderBy?: DocumentContentOrderByWithRelationInputInput[];
    }) => Connection_2;
    course: (args?: {
        where?: CourseWhereInputInput;
        orderBy?: CourseOrderByWithRelationInputInput[];
    }) => Course;
    readTime: t.Number;
    progress: t.Number;
    markedFinished: t.Boolean;
    status: t.Nullable<DocumentContentStatus>;
    createdAt: t.Date;
    constructor() { this.__typename = ""; this.id = ""; this.title = ""; this.contentId = null; this.content = fnProxy(Connection_2); this.course = fnProxy(Course); this.readTime = null; this.progress = null; this.markedFinished = false; this.status = null; this.createdAt = ""; }
}
export class Connection_2 {
    __typename: t.String;
    nodes: DocumentContent[];
    edges: Edge_2[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(DocumentContent); this.edges = arrayProxy(Edge_2); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class DocumentContent {
    __typename: t.String;
    id: t.String;
    title: t.String;
    text: t.String;
    progress: t.Number;
    ttsUrl: t.Nullable<t.String>;
    images: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: DocumentContentImageWhereInputInput;
        orderBy?: DocumentContentImageOrderByWithRelationInputInput[];
    }) => Connection_3;
    glossaryTerms: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: GlossaryTermWhereInputInput;
        orderBy?: GlossaryTermOrderByWithRelationInputInput[];
    }) => Connection_4;
    document: (args?: {
        where?: DocumentWhereInputInput;
        orderBy?: DocumentOrderByWithRelationInputInput[];
    }) => Document;
    exam: (args?: {
        where?: ExamWhereInputInput;
        orderBy?: ExamOrderByWithRelationInputInput[];
    }) => t.Nullable<Exam>;
    chat: (args?: {
        where?: DocumentChatWhereInputInput;
        orderBy?: DocumentChatOrderByWithRelationInputInput[];
    }) => t.Nullable<DocumentChat>;
    createdAt: t.Date;
    constructor() { this.__typename = ""; this.id = ""; this.title = ""; this.text = ""; this.progress = null; this.ttsUrl = null; this.images = fnProxy(Connection_3); this.glossaryTerms = fnProxy(Connection_4); this.document = fnProxy(Document); this.exam = fnProxy(Exam); this.chat = fnProxy(DocumentChat); this.createdAt = ""; }
}
export class Connection_3 {
    __typename: t.String;
    nodes: DocumentContentImage[];
    edges: Edge_3[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(DocumentContentImage); this.edges = arrayProxy(Edge_3); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class DocumentContentImage {
    __typename: t.String;
    id: t.String;
    url: t.String;
    thumbUrl: t.Nullable<t.String>;
    content: (args?: {
        where?: DocumentContentWhereInputInput;
        orderBy?: DocumentContentOrderByWithRelationInputInput[];
    }) => DocumentContent;
    constructor() { this.__typename = ""; this.id = ""; this.url = ""; this.thumbUrl = null; this.content = fnProxy(DocumentContent); }
}
export class Edge_3 {
    __typename: t.String;
    cursor: t.String;
    node: DocumentContentImage;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(DocumentContentImage); }
}
export class PageInfo {
    __typename: t.String;
    hasNextPage: t.Boolean;
    hasPreviousPage: t.Boolean;
    startCursor: t.Nullable<t.String>;
    endCursor: t.Nullable<t.String>;
    constructor() { this.__typename = ""; this.hasNextPage = false; this.hasPreviousPage = false; this.startCursor = null; this.endCursor = null; }
}
export class Connection_4 {
    __typename: t.String;
    nodes: GlossaryTerm[];
    edges: Edge_4[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(GlossaryTerm); this.edges = arrayProxy(Edge_4); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class GlossaryTerm {
    __typename: t.String;
    id: t.String;
    term: t.String;
    definition: t.String;
    content: (args?: {
        where?: DocumentContentWhereInputInput;
        orderBy?: DocumentContentOrderByWithRelationInputInput[];
    }) => DocumentContent;
    constructor() { this.__typename = ""; this.id = ""; this.term = ""; this.definition = ""; this.content = fnProxy(DocumentContent); }
}
export class Edge_4 {
    __typename: t.String;
    cursor: t.String;
    node: GlossaryTerm;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(GlossaryTerm); }
}
export class Exam {
    __typename: t.String;
    id: t.String;
    questions: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: ExamQuestionWhereInputInput;
        orderBy?: ExamQuestionOrderByWithRelationInputInput[];
    }) => Connection_5;
    content: (args?: {
        where?: DocumentContentWhereInputInput;
        orderBy?: DocumentContentOrderByWithRelationInputInput[];
    }) => DocumentContent;
    constructor() { this.__typename = ""; this.id = ""; this.questions = fnProxy(Connection_5); this.content = fnProxy(DocumentContent); }
}
export class Connection_5 {
    __typename: t.String;
    nodes: ExamQuestion[];
    edges: Edge_5[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(ExamQuestion); this.edges = arrayProxy(Edge_5); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class ExamQuestion {
    __typename: t.String;
    id: t.String;
    question: t.String;
    answers: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: ExamQuestionAnswerWhereInputInput;
        orderBy?: ExamQuestionAnswerOrderByWithRelationInputInput[];
    }) => Connection_6;
    exam: (args?: {
        where?: ExamWhereInputInput;
        orderBy?: ExamOrderByWithRelationInputInput[];
    }) => Exam;
    constructor() { this.__typename = ""; this.id = ""; this.question = ""; this.answers = fnProxy(Connection_6); this.exam = fnProxy(Exam); }
}
export class Connection_6 {
    __typename: t.String;
    nodes: ExamQuestionAnswer[];
    edges: Edge_6[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(ExamQuestionAnswer); this.edges = arrayProxy(Edge_6); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class ExamQuestionAnswer {
    __typename: t.String;
    id: t.String;
    answer: t.String;
    isCorrect: t.Boolean;
    examQuestion: (args?: {
        where?: ExamQuestionWhereInputInput;
        orderBy?: ExamQuestionOrderByWithRelationInputInput[];
    }) => ExamQuestion;
    constructor() { this.__typename = ""; this.id = ""; this.answer = ""; this.isCorrect = false; this.examQuestion = fnProxy(ExamQuestion); }
}
export class Edge_6 {
    __typename: t.String;
    cursor: t.String;
    node: ExamQuestionAnswer;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(ExamQuestionAnswer); }
}
export class Edge_5 {
    __typename: t.String;
    cursor: t.String;
    node: ExamQuestion;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(ExamQuestion); }
}
export class DocumentChat {
    __typename: t.String;
    id: t.String;
    messages: (args?: {
        pagination?: ConnectionArgumentsInput;
        where?: DocumentChatMessageWhereInputInput;
        orderBy?: DocumentChatMessageOrderByWithRelationInputInput[];
    }) => Connection_7;
    documentContent: (args?: {
        where?: DocumentContentWhereInputInput;
        orderBy?: DocumentContentOrderByWithRelationInputInput[];
    }) => DocumentContent;
    constructor() { this.__typename = ""; this.id = ""; this.messages = fnProxy(Connection_7); this.documentContent = fnProxy(DocumentContent); }
}
export class Connection_7 {
    __typename: t.String;
    nodes: DocumentChatMessage[];
    edges: Edge_7[];
    pageInfo: PageInfo;
    totalCount: t.Number;
    constructor() { this.__typename = ""; this.nodes = arrayProxy(DocumentChatMessage); this.edges = arrayProxy(Edge_7); this.pageInfo = proxy(PageInfo); this.totalCount = null; }
}
export class DocumentChatMessage {
    __typename: t.String;
    id: t.String;
    text: t.String;
    response: t.String;
    chat: (args?: {
        where?: DocumentChatWhereInputInput;
        orderBy?: DocumentChatOrderByWithRelationInputInput[];
    }) => DocumentChat;
    createdAt: t.Date;
    constructor() { this.__typename = ""; this.id = ""; this.text = ""; this.response = ""; this.chat = fnProxy(DocumentChat); this.createdAt = ""; }
}
export class Edge_7 {
    __typename: t.String;
    cursor: t.String;
    node: DocumentChatMessage;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(DocumentChatMessage); }
}
export class Edge_2 {
    __typename: t.String;
    cursor: t.String;
    node: DocumentContent;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(DocumentContent); }
}
export class Edge_1 {
    __typename: t.String;
    cursor: t.String;
    node: Document;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(Document); }
}
export class Edge {
    __typename: t.String;
    cursor: t.String;
    node: Course;
    constructor() { this.__typename = ""; this.cursor = ""; this.node = proxy(Course); }
}
export class Task {
    __typename: t.String;
    id: t.String;
    status: t.Nullable<COMPLETED_WAITING_ACTIVE_DELAYED_FAILED_PAUSED_STUCK>;
    progress: JobProgress;
    data: JobData;
    constructor() { this.__typename = ""; this.id = ""; this.status = null; this.progress = proxy(JobProgress); this.data = proxy(JobData); }
}
export class JobProgress {
    __typename: t.String;
    currentStep: t.Number;
    totalSteps: t.Number;
    percent: t.Number;
    constructor() { this.__typename = ""; this.currentStep = null; this.totalSteps = null; this.percent = null; }
}
export class JobData {
    __typename: t.String;
    userId: t.String;
    courseId: t.String;
    documentId: t.String;
    documentFileId: t.String;
    documentContent: t.Nullable<JobDocumentContent>[];
    constructor() { this.__typename = ""; this.userId = ""; this.courseId = ""; this.documentId = ""; this.documentFileId = ""; this.documentContent = arrayProxy(JobDocumentContent); }
}
export class JobDocumentContent {
    __typename: t.String;
    title: t.String;
    text: t.String;
    ttsUrl: t.String;
    glossary: t.NotSupportedYet;
    exam: Exam_1;
    images: Images[];
    constructor() { this.__typename = ""; this.title = ""; this.text = ""; this.ttsUrl = ""; this.glossary = null; this.exam = proxy(Exam_1); this.images = arrayProxy(Images); }
}
export class Exam_1 {
    __typename: t.String;
    questions: Questions[];
    constructor() { this.__typename = ""; this.questions = arrayProxy(Questions); }
}
export class Questions {
    __typename: t.String;
    question: t.String;
    answers: Answers[];
    constructor() { this.__typename = ""; this.question = ""; this.answers = arrayProxy(Answers); }
}
export class Answers {
    __typename: t.String;
    answer: t.String;
    isCorrect: t.Boolean;
    constructor() { this.__typename = ""; this.answer = ""; this.isCorrect = false; }
}
export class Images {
    __typename: t.String;
    url: t.String;
    thumbUrl: t.Nullable<t.String>;
    constructor() { this.__typename = ""; this.url = ""; this.thumbUrl = null; }
}
export class UserToken {
    __typename: t.String;
    id: t.String;
    userId: t.String;
    amount: t.Number;
    constructor() { this.__typename = ""; this.id = ""; this.userId = ""; this.amount = null; }
}
export class Mutation {
    __typename: t.String;
    courseCreate: (args: {
        data: ObjectAndCourseUncheckedCreateInputInput;
    }) => Course;
    courseUpdate: (args: {
        data: ObjectAndCourseUncheckedUpdateInputInput;
        where?: WhereInputAndWhereInput_1;
    }) => Course;
    courseDelete: (args?: {
        where?: WhereInputAndWhereInput_1;
    }) => Course;
    courseDocumentUpload: (args: {
        args: ArgsInput;
    }) => Task;
    courseDocumentChat: (args: {
        documentContentId: t.String;
        text: t.String;
    }) => DocumentChat;
    courseDocumentUpdate: (args: {
        data: WithoutInputAndDocumentUncheckedUpdateInputInput;
        where?: WhereInput_3AndWhereInput_4;
    }) => Document;
    courseDocumentDelete: (args?: {
        where?: WhereInput_3AndWhereInput_4;
    }) => Document;
    courseDocumentContentUpdate: (args: {
        data: WithoutInput_2AndDocumentContentUncheckedUpdateInputInput;
        where?: WhereInput_6AndWhereInput_7;
    }) => DocumentContent;
    updateDocumentContentProgress: (args: {
        contentId: t.String;
        documentId: t.String;
        progress: t.Number;
    }) => t.Boolean;
    courseChangeTitle: (args: {
        userId: t.String;
        courseId: t.String;
        title: t.String;
    }) => t.Boolean;
    betaTokenClaim: (args: {
        userId: t.String;
        id: t.String;
    }) => t.Boolean;
    constructor() { this.__typename = ""; this.courseCreate = fnProxy(Course); this.courseUpdate = fnProxy(Course); this.courseDelete = fnProxy(Course); this.courseDocumentUpload = fnProxy(Task); this.courseDocumentChat = fnProxy(DocumentChat); this.courseDocumentUpdate = fnProxy(Document); this.courseDocumentDelete = fnProxy(Document); this.courseDocumentContentUpdate = fnProxy(DocumentContent); this.updateDocumentContentProgress = () => false; this.courseChangeTitle = () => false; this.betaTokenClaim = () => false; }
}

