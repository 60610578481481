import { sq } from '../../api/src';
import { useAuthenticationContext } from '@atsnek/jaen';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';

export type UserContext = {
  tokenCount: number | null;
  fetchTokenCount: () => void;
};

export const UserContext = createContext<UserContext>({
  tokenCount: null,
  fetchTokenCount: () => {}
});

/**
 * Provider for the user context.
 */
const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [tokenCount, setTokenCount] = useState<number | null>(null);
  const userId = useAuthenticationContext().user?.id;

  const fetchTokenCount = async () => {
    // const tokenCount = await getTokenCount();
    const [token] = await sq.query(q => q.userToken({ userId: userId! }).amount);
    setTokenCount(token);
  };

  useEffect(() => {
    if (!userId || tokenCount) return;
    fetchTokenCount();
  }, [userId]);

  return (
    <UserContext.Provider value={{ tokenCount, fetchTokenCount }}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
