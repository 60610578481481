import { makeSnekQuery } from 'snek-query';
import { Query, Mutation } from './schema.generated';

export const sq = makeSnekQuery(
  { Query, Mutation },
  {
    apiURL: 'https://study-pylon.cronit.io/graphql',
    middlewares: [
      ({ context }) => {
        context.headers['x-beta-token'] = localStorage.getItem('beta-access-token');
      }
    ]
  }
);
