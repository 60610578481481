import { ChakraComponent, IconProps, chakra } from '@chakra-ui/react';
import { SVGProps } from 'react';

const Logo: React.FC<IconProps> = props => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      enableBackground="new 0 0 120 120"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      width="100%"
      height="100%"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#F65314"
            d="M59.69,56.224c-0.485,0-0.879,0.393-0.879,0.879c0,0.486,0.394,0.88,0.879,0.88
			c0.486,0,0.88-0.394,0.88-0.88C60.57,56.617,60.177,56.224,59.69,56.224z"
          />
          <path
            fill="#F65314"
            d="M54.415,56.224c-0.486,0-0.88,0.393-0.88,0.879c0,0.486,0.394,0.88,0.88,0.88
			c0.485,0,0.879-0.394,0.879-0.88C55.294,56.617,54.9,56.224,54.415,56.224z"
          />
          <path
            fill="#F65314"
            d="M64.968,56.224c-0.486,0-0.88,0.393-0.88,0.879c0,0.486,0.393,0.88,0.88,0.88
			c0.485,0,0.879-0.394,0.879-0.88C65.847,56.617,65.453,56.224,64.968,56.224z"
          />
          <path
            fill="#F65314"
            d="M62.329,60.62c-0.486,0-0.879,0.395-0.879,0.88c0,0.485,0.393,0.879,0.879,0.879
			c0.486,0,0.879-0.394,0.879-0.879C63.208,61.015,62.815,60.62,62.329,60.62z"
          />
          <path
            fill="#F65314"
            d="M67.605,60.62c-0.485,0-0.88,0.395-0.88,0.88c0,0.485,0.395,0.879,0.88,0.879
			c0.485,0,0.88-0.394,0.88-0.879C68.485,61.015,68.091,60.62,67.605,60.62z"
          />
          <path
            fill="#F65314"
            d="M57.053,60.62c-0.485,0-0.88,0.395-0.88,0.88c0,0.485,0.395,0.879,0.88,0.879
			c0.485,0,0.88-0.394,0.88-0.879C57.932,61.015,57.538,60.62,57.053,60.62z"
          />
          <path
            fill="#F65314"
            d="M51.776,60.62c-0.486,0-0.879,0.395-0.879,0.88c0,0.485,0.392,0.879,0.879,0.879
			c0.486,0,0.879-0.394,0.879-0.879C52.655,61.015,52.263,60.62,51.776,60.62z"
          />
          <path
            fill="#F65314"
            d="M59.69,65.018c-0.485,0-0.879,0.394-0.879,0.879c0,0.486,0.394,0.88,0.879,0.88
			c0.486,0,0.88-0.394,0.88-0.88C60.57,65.411,60.177,65.018,59.69,65.018z"
          />
          <path
            fill="#F65314"
            d="M54.415,65.018c-0.486,0-0.88,0.394-0.88,0.879c0,0.486,0.394,0.88,0.88,0.88
			c0.485,0,0.879-0.394,0.879-0.88C55.294,65.411,54.9,65.018,54.415,65.018z"
          />
          <path
            fill="#F65314"
            d="M64.968,65.018c-0.486,0-0.88,0.394-0.88,0.879c0,0.486,0.393,0.88,0.88,0.88
			c0.485,0,0.879-0.394,0.879-0.88C65.847,65.411,65.453,65.018,64.968,65.018z"
          />
          <path
            fill="#F65314"
            d="M62.329,69.414c-0.486,0-0.879,0.395-0.879,0.88c0,0.485,0.393,0.879,0.879,0.879
			c0.486,0,0.879-0.394,0.879-0.879C63.208,69.809,62.815,69.414,62.329,69.414z"
          />
          <path
            fill="#F65314"
            d="M57.053,69.414c-0.485,0-0.88,0.395-0.88,0.88c0,0.485,0.395,0.879,0.88,0.879
			c0.485,0,0.88-0.394,0.88-0.879C57.932,69.809,57.538,69.414,57.053,69.414z"
          />
        </g>
        <path
          fill="#F65314"
          d="M71.817,28.273c0,0-0.666,3.962-3.883,6.727c-2.516-1.26-5.358-1.841-8.371-1.841
		c-3.012,0-5.855,0.581-8.371,1.842c-3.215-2.766-3.827-6.728-3.827-6.728s-5.23,2.264-3.878,8.566l0.027-0.006
		c1.424-1.075,3.189-1.72,5.11-1.72c0.665,0,1.31,0.084,1.931,0.229c0.014,0.003,0.027,0.007,0.041,0.011
		c3.74,0.891,6.524,4.244,6.524,8.257c0,1.952-0.665,3.744-1.772,5.179l4.215,4.215l4.215-4.215
		c-1.106-1.435-1.771-3.227-1.771-5.179c0-4.012,2.784-7.366,6.524-8.257c0.014-0.003,0.027-0.008,0.041-0.011
		c0.622-0.145,1.267-0.229,1.932-0.229c1.922,0,3.688,0.645,5.111,1.72l0.024,0.006C76.991,30.536,71.817,28.273,71.817,28.273z"
        />
        <g>
          <path
            fill="#FFBB00"
            d="M47.839,57.338c0-1.883,0.068-3.626,0.185-5.263c-2.538-0.179-4.763-1.468-6.199-3.39
			c-0.534,2.53-0.825,5.402-0.825,8.652c0,17.488,8.311,19.788,18.564,19.788C53.089,77.126,47.839,74.826,47.839,57.338z"
          />
          <path
            fill="#FFBB00"
            d="M77.304,48.686c-1.437,1.922-3.662,3.211-6.199,3.39c0.117,1.637,0.185,3.38,0.185,5.263
			c0,17.488-5.249,19.788-11.726,19.788c10.254,0,18.565-2.3,18.565-19.788C78.129,54.088,77.837,51.216,77.304,48.686z"
          />
          <path
            fill="#FFBB00"
            d="M81.62,67.355c0,2.16-1.748,3.908-3.908,3.908c2.16,0,3.908,1.748,3.908,3.909
			c0-2.161,1.748-3.909,3.908-3.909C83.368,71.263,81.62,69.515,81.62,67.355z"
          />
          <circle fill="#FFBB00" cx="32.205" cy="64.423" r="3.908" />
          <circle fill="#FFBB00" cx="36.675" cy="35.107" r="3.908" />
          <path
            fill="#FFBB00"
            d="M86.923,41.948c0,2.16-1.748,3.908-3.908,3.908c2.16,0,3.908,1.748,3.908,3.909
			c0-2.161,1.748-3.909,3.908-3.909C88.671,45.856,86.923,44.108,86.923,41.948z"
          />
        </g>
        <g>
          <path
            fill="#00A1F1"
            d="M48.625,35.114c-4.692,0-8.496,3.804-8.496,8.496s3.804,8.496,8.496,8.496s8.496-3.804,8.496-8.496
			S53.317,35.114,48.625,35.114z M48.625,46.54c-1.619,0-2.932-1.312-2.932-2.931c0-1.619,1.312-2.932,2.932-2.932
			c1.618,0,2.931,1.312,2.931,2.932C51.556,45.228,50.243,46.54,48.625,46.54z"
          />
          <path
            fill="#00A1F1"
            d="M70.503,35.113c-4.692,0-8.496,3.805-8.496,8.496c0,4.692,3.804,8.496,8.496,8.496
			c4.692,0,8.496-3.804,8.496-8.496C78.999,38.917,75.195,35.113,70.503,35.113z M70.503,46.54c-1.619,0-2.932-1.312-2.932-2.931
			c0-1.619,1.312-2.932,2.932-2.932c1.619,0,2.932,1.312,2.932,2.932C73.434,45.228,72.122,46.54,70.503,46.54z"
          />
        </g>
        <path
          fill="#7CBB00"
          d="M31.429,77.204c-1.787,0.36-3.132,1.938-3.132,3.83v5.864c0,1.893,1.345,3.47,3.132,3.83
		C34.22,86.968,34.22,80.965,31.429,77.204z"
        />
        <g>
          <path
            fill="#1A171B"
            d="M90.831,77.859c0.404,0,0.732-0.328,0.732-0.732c0-0.404-0.328-0.732-0.732-0.732h-21.15
			c5.244-2.107,9.182-7.152,9.182-19.055c0-3.097-0.296-5.885-0.815-8.431c1.058-1.502,1.684-3.327,1.684-5.3
			c0-5.088-4.14-9.229-9.229-9.229c-0.632,0-1.25,0.064-1.846,0.186c-2.621-1.404-5.68-2.14-9.094-2.14
			c-3.414,0-6.476,0.733-9.097,2.14c-0.596-0.121-1.212-0.186-1.842-0.186c-5.089,0-9.229,4.141-9.229,9.229
			c0,1.962,0.62,3.779,1.668,5.275c-0.516,2.552-0.797,5.357-0.797,8.455c0,11.902,3.938,16.947,9.181,19.055H32.206
			c-0.307,0-0.617,0.031-0.922,0.092h-0.001c-2.155,0.436-3.72,2.349-3.72,4.549v5.864c0,2.201,1.564,4.115,3.72,4.549
			c0.009,0.002,0,0.001,0.009,0.002c0.297,0.058,0.615,0.091,0.914,0.091h58.625c0.404,0,0.732-0.328,0.732-0.732
			c0-0.405-0.328-0.733-0.732-0.733h-1.662c-2.633-3.443-2.633-8.772,0-12.216H90.831z M66.909,75.766
			c3.213-2.558,5.113-7.911,5.113-18.427c0-1.616-0.058-3.144-0.148-4.614c1.91-0.286,3.63-1.156,4.971-2.429
			c0.358,2.154,0.551,4.494,0.551,7.043C77.395,70.12,72.946,74.387,66.909,75.766z M55.389,49.865l3.657,3.657
			c0.138,0.138,0.324,0.215,0.518,0.215c0.195,0,0.381-0.077,0.52-0.215l3.656-3.657c1.668,1.801,4.036,2.943,6.673,2.969
			c0.089,1.435,0.145,2.925,0.145,4.506c0,17.583-5.431,19.055-10.993,19.055c-5.562,0-10.992-1.472-10.992-19.055
			c0-1.584,0.059-3.071,0.15-4.506C51.355,52.806,53.722,51.663,55.389,49.865z M78.266,43.609c0,4.281-3.482,7.764-7.764,7.764
			c-4.28,0-7.764-3.482-7.764-7.764c0-4.28,3.483-7.763,7.764-7.763C74.784,35.846,78.266,39.328,78.266,43.609z M59.563,33.892
			c2.594,0,4.942,0.485,7.042,1.363c-3.144,1.474-5.331,4.659-5.331,8.354c0,1.887,0.57,3.641,1.545,5.104l-3.256,3.256
			l-3.255-3.256c0.976-1.463,1.546-3.217,1.546-5.104c0-3.714-2.211-6.914-5.381-8.377C54.581,34.348,56.96,33.892,59.563,33.892z
			 M40.861,43.609c0-4.28,3.483-7.763,7.764-7.763c4.28,0,7.763,3.482,7.763,7.763c0,4.281-3.482,7.764-7.763,7.764
			C44.345,51.372,40.861,47.89,40.861,43.609z M41.731,57.339c0-2.55,0.197-4.886,0.557-7.038c1.344,1.272,3.066,2.142,4.981,2.426
			c-0.098,1.469-0.162,2.989-0.162,4.612c0,10.516,1.898,15.869,5.112,18.427C46.182,74.387,41.731,70.12,41.731,57.339z
			 M31.115,89.881c-1.231-0.45-2.085-1.638-2.085-2.982v-5.864c0-1.344,0.853-2.531,2.085-2.981
			C33.326,81.423,33.326,86.51,31.115,89.881z M87.38,90.075H32.723c2.053-3.639,2.053-8.576,0-12.216H87.38
			C85.134,81.514,85.134,86.42,87.38,90.075z"
          />
          <path
            fill="#1A171B"
            d="M59.075,85.433h-6.591c-0.404,0-0.733,0.328-0.733,0.732c0,0.404,0.329,0.732,0.733,0.732h6.591
			c0.404,0,0.732-0.328,0.732-0.732C59.807,85.761,59.479,85.433,59.075,85.433z"
          />
          <path
            fill="#1A171B"
            d="M63.227,86.165c0,0.404,0.328,0.732,0.733,0.732h17.601c0.404,0,0.732-0.328,0.732-0.732
			c0-0.404-0.328-0.732-0.732-0.732H63.96C63.556,85.433,63.227,85.761,63.227,86.165z"
          />
          <path
            fill="#1A171B"
            d="M39.781,82.501h26.625c0.404,0,0.733-0.328,0.733-0.732c0-0.405-0.329-0.733-0.733-0.733H39.781
			c-0.405,0-0.733,0.328-0.733,0.733C39.048,82.173,39.376,82.501,39.781,82.501z"
          />
          <path
            fill="#1A171B"
            d="M71.292,82.501h3.429c0.404,0,0.733-0.328,0.733-0.732c0-0.405-0.329-0.733-0.733-0.733h-3.429
			c-0.404,0-0.732,0.328-0.732,0.733C70.559,82.173,70.888,82.501,71.292,82.501z"
          />
          <path
            fill="#1A171B"
            d="M28.297,51.48c0.405,0,0.733-0.329,0.733-0.733V28.273c0-0.404-0.328-0.732-0.733-0.732
			c-0.404,0-0.733,0.328-0.733,0.732v22.474C27.563,51.151,27.892,51.48,28.297,51.48z"
          />
          <path
            fill="#1A171B"
            d="M28.297,60.034c0.405,0,0.733-0.328,0.733-0.732v-3.67c0-0.404-0.328-0.733-0.733-0.733
			c-0.404,0-0.733,0.329-0.733,0.733v3.67C27.563,59.706,27.892,60.034,28.297,60.034z"
          />
          <path
            fill="#1A171B"
            d="M90.831,57.594c-0.404,0-0.732,0.328-0.732,0.732v14.655c0,0.405,0.328,0.733,0.732,0.733
			c0.404,0,0.732-0.328,0.732-0.733V58.326C91.563,57.922,91.235,57.594,90.831,57.594z"
          />
          <path
            fill="#1A171B"
            d="M90.831,49.038c-0.404,0-0.732,0.328-0.732,0.732v3.669c0,0.405,0.328,0.733,0.732,0.733
			c0.404,0,0.732-0.328,0.732-0.733v-3.669C91.563,49.367,91.235,49.038,90.831,49.038z"
          />
        </g>
      </g>
    </chakra.svg>
  );
};

export default Logo;
